import React from 'react'
import "./InformationSection.css"

function InformationSection(
  props
) {
  return (
    <div className="information" style={{backgroundColor: props.style}}>
      <div className='container'>
        {props.children}
      </div>
    </div>
  )
}

export default InformationSection