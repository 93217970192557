import './TextChangeForm.css'
import React, { useEffect, useState } from 'react'
import TextInput from '../../../../components/input/textfield/TextInput'
import ButtonWrap from '../../../../components/input/button/ButtonWrap'
import Loader from '../../../../components/loader/Loader';
import createApiClient from '../../../../infrastructure/apis/client/ApiClient';
import apiConfig from '../../../../infrastructure/apis/client/ApiConfig';
import createMessageRepo from '../../../../infrastructure/repos/MessageRepo';
import { Box } from '@mui/material';

function TextChangeForm() {

  const buttonTexts = {
    default: "Crear nuevo mensaje",
    editMode: "Actualizar mensaje"
  }

  const stateButtonModes = {
    default: {
      text: "Me confundí",
      theme: 'error',
      message: "Estás en modo normal, este mensaje va a reemplazar el mensaje actual y va a cambiar el mensaje de ayer"
    },
    editMode: {
      text: "Mensaje nuevo",
      theme: 'success',
      message: "Estás en modo de edición, este cambio no va a actualizar el mensaje de ayer"
    }
  }

  const apiClient = createApiClient(apiConfig)
  const messageRepo = createMessageRepo(apiClient);
  const emptyMessage = {
    title: "",
    id: "",
    message: ""
  }
  const [message, setMessage] = useState(emptyMessage);
  const [actualMessage, setActualMessage] = useState(emptyMessage);

  const [showLoader, setShowLoader] = useState(false);
  const [screenState, setScreenState] = useState(false)

  useEffect(() => {
    messageRepo.fetchMessage().then(data => {
      const messageFetched = data.data.getDailyMessage;
      setActualMessage(messageFetched)
    }).catch(error => {console.log(error)})
  }, [])

  const handleScreenModeChange = (() => {
    setScreenState(!screenState)
    if (!screenState) {
      setMessage(actualMessage)
    } else {
      setMessage(emptyMessage)
    }
  })

  const handleTitleChange = (e => {
    setMessage({
      ...message,
      title: e.target.value
    })
  })

  const handleBodyChange = (e => {
    setMessage({
      ...message,
      message: e.target.value
    })
  })

  const reloadMesage = () => {
    messageRepo.fetchMessage().then(data => {
      setActualMessage(data.data.getDailyMessage)
      setMessage(data.data.getDailyMessage)
      setScreenState(true)
    }).catch(error => {console.log(error)})
  }

  const sendUpdate = async () => {
    setShowLoader(true)
    var newMessage = {
      ...actualMessage,
      title: message.title,
      message: message.message
    }
    if (screenState) { // EDIT MODE
      messageRepo.updateDailyMessage(newMessage)
      .then(() => {
        setShowLoader(false);
        reloadMesage();
      }).catch(e => {
        setShowLoader(false);
        console.log(e)
      })
    } else {
      messageRepo.fetchPastMessage().then(data => {
        var pastMessage = data.data.getDailyMessage;
        pastMessage.title = actualMessage.title;
        pastMessage.message = actualMessage.message;
        messageRepo.updateDailyMessage(pastMessage)
        .then(() => {
          messageRepo.updateDailyMessage(newMessage)
          .then(() => {
            reloadMesage();
            setShowLoader(false);
          }).catch(e => {
            setShowLoader(false);
            console.log(e)
          })
        }).catch(e => {
          setShowLoader(false);
          console.log(e)
        })
      }).catch(error => {console.log(error)})
    }
  }

  const getStateButtonText = () => {
    if (screenState) {
      return stateButtonModes.editMode.text
    } else {
      return stateButtonModes.default.text
    }
  }

  const getStateButtonColor = () => {
    if (screenState) {
      return stateButtonModes.editMode.theme
    } else {
      return stateButtonModes.default.theme
    }
  }

  const getUpdateButtonText = () => {
    if (screenState) {
      return buttonTexts.editMode
    } else {
      return buttonTexts.default
    }
  }

  const getAlertMessage = () => {
    if (screenState) {
      return stateButtonModes.editMode.message
    } else {
      return stateButtonModes.default.message
    }
  }

  const boxStyle = { 
    p: 2, 
    border: '1px dashed grey', 
    width:1, 
    borderRadius:5, 
    marginBottom:3, 
    display: 'flex',  
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <div className='text-change-form-container'>
      <div className='top-buttons-container'>
        <Box  component="span" sx={boxStyle}>
          <p style={{textAlign:"center"}}>{getAlertMessage()}</p>
          <ButtonWrap onClick={handleScreenModeChange} color={getStateButtonColor()} className='state-button' variant="contained">{getStateButtonText()}</ButtonWrap>
        </Box>
      </div>
      <TextInput onChange={handleTitleChange} id="standard-basic" label="Titulo" variant="standard" value={message.title} />
      <TextInput onChange={handleBodyChange} label="Lectura" variant="outlined" multiline rows={20} value={message.message} />
      <div className='buttons-container'>
        <ButtonWrap onClick={sendUpdate} variant="contained">{getUpdateButtonText()}</ButtonWrap>
      </div>
      <Loader open={showLoader} />
    </div>
  )
}

export default TextChangeForm