

export const getDailyMessage = `query getDailyMessage {
  getDailyMessage(id: "${(process.env.REACT_APP_DEBUG_MODE !== "true") ? '79529677-e396-47f1-8958-20e64da4b2eb' : 'e38a1fe1-1afa-459e-b84c-44b6e8f92af2'}") {
    id
    message
    title
    _version
  }
}
`;

export const getPastDailyMessage = `query getDailyMessage {
  getDailyMessage(id: "${(process.env.REACT_APP_DEBUG_MODE !== "true") ? 'bc87df16-cc56-4478-8a5d-a3f98ca96619' : '4e97c3f1-f71e-4035-9f26-a1ffe86fae5c'}") {
    id
    message
    title
    _version
  }
}
`;

export const listFutureEvents = () => {
  const date = new Date(Date.now());
  return `query listFutureEvents {
    listEvents(filter: {endDate: {gt: "${date.toISOString()}"}}) {
      startedAt
      items {
        id
        title
        message
        startDate
        endDate
        description
        location {
          locationLink
          locationName
        }
        _deleted
      }
    }
  }
  `
}

export const listAllEvents =`query listAllEvents {
    listEvents {
      items {
        id
        title
        message
        startDate
        endDate
        description
        location {
          locationLink
          locationName
        }
        _version
        _deleted
      }
    }
  }
  `