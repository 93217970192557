import authReducer from './redux/auth/AuthReducer'
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage
}

const reducers = persistCombineReducers(persistConfig ,{
  auth: authReducer
});

const store = configureStore({
  reducer: reducers,
  middleware: [thunk, logger]
})

const persistor = persistStore(store);

export {persistor};

export default store;