import './App.css';
import Home from './pages/home/Home';
import { Routes, Route } from "react-router-dom";
import Login from './pages/login/Login';
import Dashboard from './pages/admin/dashboard/Dashboard';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports'
import { Provider } from 'react-redux';
import store, {persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react';
import ResetPassword from './pages/resetpassword/ResetPassword';
import CompletePassword from './pages/completepassword/CompletePassword';
Amplify.configure(awsmobile);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path='/admin/login' element={<Login/>}/>
          <Route path='/admin/dashboard' element={<Dashboard />}/>
          <Route path='/admin/reset-password' element={<ResetPassword />} />
          <Route path='/admin/complete-password' element={<CompletePassword />} />
        </Routes>
      </PersistGate>
    </Provider>
  )
  
}

export default App;
