import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import React, { useContext, useState } from 'react'
import { ReactReduxContext } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonWrap from '../../components/input/button/ButtonWrap';
import TextInput from '../../components/input/textfield/TextInput';
import createApiClient from '../../infrastructure/apis/client/ApiClient';
import apiConfig from '../../infrastructure/apis/client/ApiConfig';
import createAuthRepo from '../../infrastructure/repos/AuthRepo';

function CompletePassword() {
  const navigate = useNavigate();
  const { store } = useContext(ReactReduxContext);

  const apiClient = createApiClient(apiConfig)
  const authRepo = createAuthRepo(apiClient)

  const emptyInput = {
    password: "",
    confirmation: ""
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#282c34',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
  });

  const [input, setInput] = useState(emptyInput);

  const changeNewPassword = (e => {
    setInput({...input, password: e.target.value})
  })

  const changeConfirmation = (e => {
    setInput({...input, confirmation: e.target.value})
  })

  const attemptFinishPasswordSetup = () => {
    if (input.confirmation == input.password) {
      authRepo.finishPasswordSetup(store.getState().auth, input.password)
      .then(user => {
        navigate("/admin/dashboard");
      })
      .catch(e => {console.log(e)})
    }
  }

  return (
    <div className='login-container'>
      <div className='login-view'>
        <h1 className='login-title'>Ingresa tu nuevo password</h1>
        <ThemeProvider theme={theme}>
          <div className='login-form-container'>
            <TextInput onChange={changeNewPassword} className='new-password-input' id="new-password-input" label="Contraseña" variant="standard" type="password"/>
            <TextInput onChange={changeConfirmation} className='new-password-confirmation-input' id="confirm-password-input" label="Confirma contraseña" variant="standard" type="password"/>
          </div>
          <ButtonWrap onClick={attemptFinishPasswordSetup} variant="contained">Ingresar</ButtonWrap>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default CompletePassword