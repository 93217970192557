const apiConfig = () => {
  const getUrl = () => {
    return process.env.REACT_APP_MCIB_URL
  }
  const getApiKey = () => {
    return process.env.REACT_APP_MCIB_API_KEY
  }
  return {
    getUrl,
    getApiKey
  }
};
export default apiConfig;