import './Dashboard.css'
import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Tab, Tabs } from '@mui/material'
import TextChangeForm from './textchangeform/TextChangeForm';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system'
import EventsEditMain from './eventsedit/EventsEditMain'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ButtonWrap from '../../../components/input/button/ButtonWrap';
import ModalView from '../../../components/modalview/ModalView';
import createApiClient from '../../../infrastructure/apis/client/ApiClient';
import apiConfig from '../../../infrastructure/apis/client/ApiConfig';
import createAuthRepo from '../../../infrastructure/repos/AuthRepo';
import { ReactReduxContext } from 'react-redux';
import { Auth } from 'aws-amplify';

function Dashboard() {
  const { store } = useContext(ReactReduxContext);
  const apiClient = createApiClient(apiConfig);
  const authRepo = createAuthRepo(apiClient);

  const [value, setValue] = React.useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const confirmCloseSession = () => {
    Cookies.remove('token');
    Auth.signOut().then(val => {
      navigate("/")
    })
  }

  const closeModalView = () => {
    setShowModal(false);
  }

  const buttonOptions = {
    primary: {
      handleClick: confirmCloseSession,
      label: "Si, cerrar sesión"
    },
    secondary: {
      handleClick: closeModalView,
      label: "Cancelar"
    }
  }

  useEffect(() => {
    const result = authRepo.isUserAuthenticated()
    .then(user => {
    })
    .catch((error) => {
      navigate("/admin/login");
    })
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleLogout = () => {
    setModalTitle("¿Estás seguro de que quieres cerrar sesión?")
    setModalBody("");
    setShowModal(true);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#282c34',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
  });

  function renderTab() {
    let selected;
    switch (value) {
      case 0: selected = <TextChangeForm />
      break;
      case 1: selected = <EventsEditMain />
      break;
      default: selected = null;
      break;
    }
    return (<div className='tab-content'>
      {selected}
    </div>)
  }
  
  return (
    <div className='dashboard-container'>
      <div className='log-out-container'>
        <ButtonWrap variant="outlined" color="error" onClick={handleLogout}>Cerrar sesión</ButtonWrap>
      </div>
      <div className='dashboard-inside-container'>
        <div className='dashboard-blocking-view'>
          <ThemeProvider theme={theme}>
            <div className='dashboard-selector'>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Evangelio" {...a11yProps(0)} />
                  <Tab label="Eventos" {...a11yProps(1)} />
                </Tabs>
              </Box>
              {renderTab()}
            </div>
            <div className='message-form'>
            </div>
          </ThemeProvider>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <ModalView show={showModal} onClose={closeModalView} title={modalTitle} body={""} buttons={buttonOptions}/> 
      </ThemeProvider>
    </div>
  )
}

export default Dashboard