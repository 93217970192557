import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import "./PhotoFrame.css"



function PhotoFrame(props) {
  const classNameIndex = `photoFrame ${props.elementIndex}`
  return (
    <div className={classNameIndex}>
        <img src={props.img}></img>
        <div className='photoOverlay' onClick={props.onClick}>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
        </div>
    </div>
  )
}

export default PhotoFrame