import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import { Button } from '../Button/Button'
import './Navbar.css'

function Navbar({
  topRef,
  aboutRef,
  activitiesRef,
  galleryRef,
  contactRef
}
) {

  const [click, setClick] = useState(true);
  const [isMobile, setMobile] = useState(true);
  const [scrolledDown, setScrolledDown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(true);

  const refreshPage = () => {
    window.location.reload(false);
  }

  const scrollToTop = () => {
    scrollToRef(topRef)
  }

  const scrollToAboutRef = () => {
    scrollToMidRef(aboutRef)
  };

  const scrollToActivitiesRef = () => {
    if (!isMobile) {
      scrollToMidRef(activitiesRef)
    } else {
      scrollToRef(activitiesRef, 82)
    }
  };

  const scrollToGalleryRef = () => {
    if (!isMobile) {
      scrollToMidRef(galleryRef)
    } else {
      scrollToMidRef(galleryRef)
    }
  }

  const scrollToContactRef = () => {
    scrollToMidRef(contactRef);
  }

  const scrollToRef = (ref, offsetTop = 0) => {
    window.scrollTo({
      top: ref.current.offsetTop - offsetTop,
      left: 0,
      behavior: 'smooth'
    })
    closeMobileMenu();
  }

  const scrollToMidRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
    closeMobileMenu();
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolledDown(window.pageYOffset > 2);
    }
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 960)
    }
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <nav className={scrolledDown ? 'navbar' : 'navbar transparent'}>
        <div className='navbar-container'>
          <div className={scrolledDown ? 'navbar-logo white' : 'navbar-logo white'} onClick={refreshPage}>
            <p>
              <strong>Ministerio católico misionero<br/>de intercesión Betel</strong>
            </p>
          </div>
          <div className={click ? 'menu-icon bars' : 'menu-icon xmark'} onClick={handleClick}>
            <FontAwesomeIcon color={scrolledDown ? 'white' : 'white'} icon={click ? faBars : faXmark}></FontAwesomeIcon>
          </div>
          <ul className={click ? 'nav-menu' : 'nav-menu active'}>
            <li className='menu-item'>
              <Button buttonStyle='light' onClick={scrollToAboutRef}>Acerca de</Button>
            </li>
            <li className='menu-item'>
              <Button buttonStyle='light' onClick={scrollToActivitiesRef}>Actividades</Button>
            </li>
            <li className='menu-item'>
              <Button buttonStyle='light' onClick={scrollToGalleryRef}>Galería</Button>
            </li>
            <li className='menu-item'>
              <Button buttonStyle='light' onClick={scrollToContactRef}>Contacto</Button>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar