
const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'auth/addAuth': {
      return action.payload
    }
    case 'auth/updateAuth': {
      return action.payload
    }
    case 'auth/removeAuth': {
      return {}
    }
    default: {
      return state
    }
  }
}

export default authReducer