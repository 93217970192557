import React from 'react'
import './TextInput.css'
import { TextField } from '@mui/material'


function TextInput(
  props
) {

  const sxModifier={
    width: '100%'
  }

  return (
    <div className='text-input'>
      <TextField sx={sxModifier} {...props} />
    </div>
  )
}

export default TextInput