import { Button } from '@mui/material'
import React from 'react'
import './ButtonWrap.css'

function ButtonWrap(props) {

  return (
    <div className='button-input'>
      <Button onClick={props.onClick} variant={props.variant} {...props}>
        {props.children}
      </Button>
    </div>
  )
}

export default ButtonWrap