import { API, graphqlOperation } from "aws-amplify";
import { listAllEvents, listFutureEvents } from "../graphql/Queries";
import { createNewEvent as newEventMutation, deleteEventWithId, editEvent } from "../graphql/Mutations";
import { updateEvents } from "../../graphql/mutations";

const createEventsRepo = (apiClient) => {
  const fetchEvents = async () => {
    const events = apiClient.execute('/events', 'GET', {}, {})
    return events;
  }

  const fetchFutureEvents = async () => {
    const query = listFutureEvents();
    const data = await API.graphql(graphqlOperation(query));
    const filteredItems = data.data.listEvents.items.filter(f => !f._deleted)
    const filteredData = {
      data: {
        listEvents: {
          items: filteredItems
        }
      }
    }; 
    return filteredData;
  }

  const fetchAllEvents = async () => {
    const data = await API.graphql(graphqlOperation(listAllEvents));
    const filteredItems = data.data.listEvents.items.filter(f => !f._deleted)
    const filteredData = {
      data: {
        listEvents: {
          items: filteredItems
        }
      }
    }; 
    return filteredData;
  }

  const updateExistingEvent = async (event) => {
    delete event._deleted;
    return API.graphql({
      query: updateEvents,
      variables: {input: event}
    })
  }

  const createNewEvent = async (event) => {
    const query = newEventMutation(event);
    return await API.graphql(graphqlOperation(query))
  }

  const deleteEventById = async (id, version) => {
    const query = deleteEventWithId(id, version);
    return await API.graphql(graphqlOperation(query))
  }

  return {fetchEvents, fetchFutureEvents, fetchAllEvents, createNewEvent, deleteEventById, updateExistingEvent}
}

export default createEventsRepo;