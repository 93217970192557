import './EditEvent.css'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenSquare, faTrash } from '@fortawesome/free-solid-svg-icons'

function EditEvent(props) {

  const handleEditClick = () => {
    props.handleEditEvent(props.id)
  }

  const handleDeleteClick = () => {
    props.handleDeleteEvent(props.id)
  }

  return (
    <div className='edit-event-container'>
      <div className='edit-event-information'>
        <h2>{props.title}</h2>
        <p>{props.date}</p>
      </div>
      <div className='edit-event-buttons'>
        <div className='edit-event-button' onClick={handleEditClick}>
          <FontAwesomeIcon icon={faPenSquare} className="fa-2x" />
        </div>
        <div className='edit-event-button' onClick={handleDeleteClick}>
          <FontAwesomeIcon icon={faTrash} className="fa-2x" />
        </div>
      </div>
    </div>
  )
}

export default EditEvent