import React from 'react'
import './DailyMessage.css'
import { Box } from '@mui/material'
import ButtonWrap from '../input/button/ButtonWrap'

function DailyMessage({
    title,
    message,
    toggleMessage,
    isPresent
}) {
  const buttonStyle = { 
    color: 'white',
    borderColor: 'white',
    opacity: 0.75,
    ":hover": {
      borderColor: "#f4623a"
    }
  }

  const getButtonText = () => {
    if (isPresent) {
      return "Quiero ver el mensaje de ayer"
    } else {
      return "Quiero ver el mensaje de hoy"
    }
  }

  return (
    <section className='dailyMessage-container'>
      <div className='daily-message-buttons'></div>
      <div className='dailyMessage'>
          <h1 className='dailyMessage title'>{title}</h1>
          <hr className='divider'/>
          <p className='dailyMessage body'>{message}</p>
      </div>
      <div className='daily-message-buttons'>
        <ButtonWrap onClick={toggleMessage} variant='outlined' sx={buttonStyle}>{getButtonText()}</ButtonWrap>
      </div>
    </section>
  )
}

export default DailyMessage