import { ThemeProvider } from '@emotion/react'
import { Alert, createTheme } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonWrap from '../../components/input/button/ButtonWrap'
import TextInput from '../../components/input/textfield/TextInput'
import createApiClient from '../../infrastructure/apis/client/ApiClient'
import apiConfig from '../../infrastructure/apis/client/ApiConfig'
import createAuthRepo from '../../infrastructure/repos/AuthRepo'
import './ResetPassword.css'

function ResetPassword() {
  const apiClient = createApiClient(apiConfig)
  const authRepo = createAuthRepo(apiClient)
  const navigate = useNavigate();
  const emptyInputState = {
    email: "",
    code: "",
    password: "",
    confirmation: ""
  }
  const emptyAlertState = {
    show: false,
    message: ""
  }

  const [inputState, setInputState] = useState(emptyInputState)
  const [alert, setAlert] = useState(emptyAlertState);

  const changeEmail = (e) => {
    setInputState({...inputState, email: e.target.value})
  }

  const changeCode = (e) => {
    setInputState({...inputState, code: e.target.value})
  }

  const changeNewPassword = (e) => {
    setInputState({...inputState, password: e.target.value})
  }

  const changeConfirmation = (e) => {
    setInputState({...inputState, confirmation: e.target.value})
  }

  const showAlert = (alertMessage) => {
    setAlert({
      show: true,
      message: alertMessage
    })
  }

  const attemptChangePassword = () => {
    if (inputState.password === inputState.confirmation) {
      authRepo.forgotPasswordFinish(
        inputState.email,
        inputState.code,
        inputState.password
      ).then(val => {
        console.log(val)
        if (!(val instanceof Error)) {
          navigate('/admin/login')
        } else if (val.name === 'ExpiredCodeException') {
          showAlert('Código expirado, solicita uno nuevo')
        } else if (val.name === 'CodeMismatchException') {
          showAlert('El código no es válido')
        } else if (val.name === 'InvalidPasswordException') {
          showAlert('La contraseña no cumple con los estándares de seguridad')
        } else {
          showAlert(val.name);
        }
      })
    } else {
      showAlert('Las contraseñas no coinciden')
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#282c34',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
  });

  const renderAlert = () => {
    if (alert.show) {
      return <Alert severity="error">{alert.message}</Alert>
    } else {
      return null;
    }
  }

  return (
    <div className='reset-password-container'>
      <div className='reset-password-view'>
        <h1 className='reset-password-title'>Cambia tu contraseña</h1>
        <ThemeProvider theme={theme}>
          <div className='reset-password-form-container'>
            {renderAlert()}
            <TextInput onChange={changeEmail} id="email-input" label="Correo Electrónico" variant="standard" />
            <TextInput onChange={changeCode} className='login-input' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} id="code-input" label="Código" variant="standard" />
            <TextInput onChange={changeNewPassword} className='login-input' id="new-password-input" label="Contraseña" variant="standard" type="password"/>
            <TextInput onChange={changeConfirmation} className='login-input' id="confirm-password-input" label="Confirma contraseña" variant="standard" type="password"/>
          </div>
          <ButtonWrap onClick={attemptChangePassword} variant="contained">Ingresar</ButtonWrap>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default ResetPassword