import './EventsEdit.css'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import TextInput from './../../../../../components/input/textfield/TextInput'
import DateInput from '../../../../../components/input/datefield/DateInput'
import ButtonWrap from '../../../../../components/input/button/ButtonWrap'
import dayjs from 'dayjs'
import Loader from '../../../../../components/loader/Loader'
import createEventsRepo from '../../../../../infrastructure/repos/EventsRepo'
import createApiClient from '../../../../../infrastructure/apis/client/ApiClient'
import apiConfig from '../../../../../infrastructure/apis/client/ApiConfig'


function EventsEdit(props) {
  const apiClient = createApiClient(apiConfig);
  const eventsRepo = createEventsRepo(apiClient);

  const emptyErrors = {
    title: "",
    startDate: "",
    endDate: "",
    message: "",
    description: "",
    location: {
      locationName: "",
      locationLink: ""
    }
  }

  const [event, setEvent] = useState(props.event)
  const [errors, setErrors] = useState(emptyErrors);
  const [sending, setSending] = useState(false);
  
  const [showLoader, setShowLoader] = useState(false);

  const validateDates = () => {
    return event.startDate
  }


  const handleEventUpdate = async () => {
    if (sending) {
      return;
    }
    if (event.title == null || event.title == "") {
      return; // At least title
    }
    setSending(true);
    setShowLoader(true);
    if (props.event.id === "") {
      eventsRepo.createNewEvent(event).then(() => {
        quitEditMode();
        setShowLoader(false);
      })
      .catch(e => {
        setShowLoader(false);
        console.log(e);
      })
    } else {
      eventsRepo.updateExistingEvent(event).then(response => {
        quitEditMode();
        setShowLoader(false);
      })
      .catch(e => {
        setShowLoader(false);
        console.log(e)
      })
    }
    setSending(false);
  }

  const handleTitleChange = (e => {
    setEvent({...event, title: e.target.value})
    if (!e.target.value) {
      setErrors({...errors, title: "No puedes tener un título vacío."})
    } else {
      setErrors({...errors, title: ""})
    }
  })

  const handleBodyChange = (e => {
    setEvent({...event, message: e.target.value})
    if (!e.target.value) {
      setErrors({...errors, message: "No puedes tener un mensaje vacío."})
    } else {
      setErrors({...errors, message: ""})
    }
  })

  const handleDescriptionChange = (e => {
    setEvent({...event, description: e.target.value})
  })

  const handleDateChange = (e => {
    var d = e.$d;
    d = new Date(d);
    setEvent({...event, startDate: d.toISOString()})
    if (!d) {
      setErrors({...errors, startDate: "No puedes tener una fecha de inicio vacía."})
    } else if (event.endDate) {
      const laterDate = new Date(event.endDate);
      if (d.getTime() > laterDate.getTime()) {
        setErrors({...errors, startDate: "No puedes tener una fecha de inicio mayor que la fecha de finalización."})
      } else {
        setErrors({...errors, startDate: ""})
      }
    } else {
      setErrors({...errors, startDate: ""})
    }
  })

  const handleEndDateChange = (e => {
    var d = e.$d;
    d = new Date(d);
    setEvent({...event, endDate: d.toISOString()})
    if (!d) {
      setErrors({...errors, endDate: "No puedes tener una fecha de finalización vacía."})
    } else if (event.startDate) {
      const earlierDate = new Date(event.startDate);
      if (d.getTime() < earlierDate.getTime()) {
        setErrors({...errors, endDate: "No puedes tener una fecha de fin menor que la fecha de inicio."})
      } else {
        setErrors({...errors, endDate: ""})
      }
    } else {
      setErrors({...errors, endDate: ""})
    }
  })

  const handleLocationNameChange = (e => {
    setEvent({...event, location: {...event.location, locationName: e.target.value}})
    if (!e.target.value) {
      setErrors({...errors, location: {...errors.location, locationName: "No puedes tener un lugar vacío"}})
    } else {
      setErrors({...errors, location: {...errors.location, locationName: ""}})
    }
  })

  const handleLocationLinkChange = (e => {
    setEvent({...event, location: {...event.location, locationLink: e.target.value}})
    if (!e.target.value) {
      setErrors({...errors, location: {...errors.location, locationLink: "No puedes tener un link del lugar vacío"}})
    } else {
      setErrors({...errors, location: {...errors.location, locationLink: ""}})
    }
  })

  const quitEditMode = () => {
    props.edit(null);
  }

  return (
    <div>
      <div className='top-bar-icons'>
        <div className='top-bar-button' onClick={quitEditMode}>
          <FontAwesomeIcon icon={faArrowLeft} className="fa-2x" />
        </div>
      </div>
      <div className='event-edit-form'>
        <TextInput onChange={handleTitleChange} id="standard-basic" label="Titulo" variant="standard" defaultValue={props.event.title} error={errors.title} helperText={errors.title}/>
        <DateInput onChange={handleDateChange} label="Fecha de inicio" defaultValue={dayjs(props.event.startDate)} error={errors.startDate} helperText={errors.startDate}/>
        <DateInput onChange={handleEndDateChange} label="Fecha de finalización" defaultValue={dayjs(props.event.endDate)} error={errors.endDate != ""} helperText={errors.endDate}/>
        <TextInput onChange={handleBodyChange} label="Mensaje" variant="outlined" multiline rows={10} defaultValue={props.event.message} error={errors.message != ""} helperText={errors.message}/>
        <TextInput onChange={handleDescriptionChange} label="Descripción" variant="outlined" multiline rows={10} defaultValue={props.event.description} error={errors.description} helperText={errors.description}/>
        <h3>Lugar</h3>
        <TextInput onChange={handleLocationNameChange} id="standard-basic" label="Nombre del lugar" variant="standard" defaultValue={props.event.location.locationName} error={errors.location.locationName} helperText={errors.location.locationLink}/>
        <TextInput onChange={handleLocationLinkChange} id="standard-basic" label="Link de ubicación" variant="standard" defaultValue={props.event.location.locationLink} error={errors.location.locationLink} helperText={errors.location.locationLink}/>
        <ButtonWrap onClick={handleEventUpdate} variant="contained" >{props.event.id === "" ? "Agregar" : "Actualizar"}</ButtonWrap>
        <Loader open={showLoader} />
      </div>
    </div>
  )
}

export default EventsEdit