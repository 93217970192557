import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

function Loader(props) {
  return (
    <div className='loader'>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} {...props}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Loader