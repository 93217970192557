import React from 'react'
import './Events.css'
import Event from './event/Event'

function Events(
    events
) {
    const eventsArray = events.events

    const parseEvent = (eventInfo, index) => {
        const stringIndex = `${index}`
        return <li key={stringIndex}>
            <Event information={eventInfo}/>
        </li>
    }
    
    const showListOfEvents = (array) => {
        if (array.length > 0) {
            return <div className='events-list-container'>
                    <ul className='events-list'>
                        {array.map((info, index) => parseEvent(info, index))}
                    </ul>
                </div>
        } else {
            return <div className='events-list-container-empty'>
                <h2>No hay eventos que mostrar por el momento.</h2>
            </div>;
        }
    }
    
  return (
    <div className='events-container'>
        <div className='events-title'>
            <h1>Eventos</h1>
        </div>
        <div className='information-container'>
            {showListOfEvents(eventsArray)}
        </div>
    </div>
  )
}

export default Events