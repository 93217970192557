import React, { useEffect } from "react";
import './ModalView.css';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonWrap from "../input/button/ButtonWrap";
import ReactLinkify from "react-linkify";


function ModalView(props) {

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  })

  const showButtons = () => {
    if (props.buttons != null) {
      return (<div className="modal-buttons">
        <ButtonWrap variant="text" onClick={props.buttons.secondary.handleClick}>{props.buttons.secondary.label}</ButtonWrap>
        <ButtonWrap variant="contained" onClick={props.buttons.primary.handleClick}>{props.buttons.primary.label}</ButtonWrap>
      </div>)
    } else {
      return null;
    }
  }

  if (props.show) {
    return (
      <div className="modal-view-blocker">
        <div className="modal-view-container">
          <div className="modal-top-bar">
            <FontAwesomeIcon color='black' icon={faXmark} className="fa-2x" onClick={props.onClose}/>
          </div>
          <div className="modal-content">
            <div className="modal-title">
                <h1>{props.title}</h1>
              </div>
            <div className="modal-body">
              <ReactLinkify>
                <p>{props.body}</p>
              </ReactLinkify>
            </div>
          </div>
          {showButtons()}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default ModalView