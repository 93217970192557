import './EventsEditList.css'
import React, { useState } from 'react'
import EditEvent from '../../../../../components/events/editevent/EditEvent'
import ModalView from '../../../../../components/modalview/ModalView'
import Loader from '../../../../../components/loader/Loader'
import ButtonWrap from '../../../../../components/input/button/ButtonWrap'
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuid } from 'uuid';
import { CircularProgress } from '@mui/material'
import createApiClient from '../../../../../infrastructure/apis/client/ApiClient'
import apiConfig from '../../../../../infrastructure/apis/client/ApiConfig'
import createEventsRepo from '../../../../../infrastructure/repos/EventsRepo'

function EventsEditList(props) {
  const apiClient = createApiClient(apiConfig);
  const eventsRepo = createEventsRepo(apiClient);

  const [showModal, setShowModal] = useState(false)
  const [showLoader, setShowLoader] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);

  const closeModalView = () => {
    setShowModal(false);
  }

  const confirmDeleteEvent = async () => {
    setShowModal(false)
    setShowLoader(true);
    const completeEvent = props.events.filter(event => event.id === activeEvent);
    console.log(completeEvent);
    eventsRepo.deleteEventById(activeEvent, completeEvent[0]._version)
    .then(data => {
      console.log(data)
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setShowLoader(false);
      setActiveEvent(false);
      props.reload();
    })
  }

  const showModalView = () => {
    setShowModal(true);
  }

  const setAddNewEvent = () => {
    const id = "";
    const emptyEvent = {
      id: "",
      title: "",
      message: "",
      location: {
        locationName: "",
        locationLink: ""
      },
      description: "",
      startDate: "",
      endDate: ""
    }
    props.edit(emptyEvent)
  }

  const setEditingEvent = (id) => {
    const selectedEvent = props.events.filter(event => {
      return event.id == id
    })
    props.edit(selectedEvent[0]);
  }

  const triggerDeleteEvent = (id) => {
    setActiveEvent(id);
    showModalView();
  }

  const parseEvent = (eventInfo, index) => {
    return <li key={index}>
        <EditEvent id={eventInfo.id} title={eventInfo.title} date={eventInfo.date} handleEditEvent={setEditingEvent} handleDeleteEvent={triggerDeleteEvent}/>
    </li>
  }

const showListOfEvents = (array) => {
  if (array == null) {
    return (<div className='events-list-container-loading'>
        <CircularProgress />
      </div>);
  } else if (array.length > 0) {
    return <div className='events-edition-list-container'>
      <ul className='events-edition-list'>
        {array.map((info, index) => parseEvent(info, index))}
        </ul>
        <ModalView show={showModal} onClose={closeModalView} title={deleteModalConfirmationTitle} body={""} buttons={buttonOptions}/> 
        <Loader open={showLoader} />
        </div>
  } else if (array.length === 0) {
    return <div className='events-list-container-empty'>
        <h2>No hay eventos que mostrar por el momento.</h2>
      </div>;
    } 
  } 

  const deleteModalConfirmationTitle = "¿Estás seguro de que quieres borrar este evento?"

  const buttonOptions = {
    primary: {
      handleClick: confirmDeleteEvent,
      label: "Si, borrar"
    },
    secondary: {
      handleClick: closeModalView,
      label: "Cancelar"
    }
  }

  return (
    <div className='events-edition-list-father-container'>
      <div className='events-list-header'>
        <h1>Eventos</h1>
        <ButtonWrap variant="text" startIcon={<AddIcon />} onClick={setAddNewEvent}>Agregar evento</ButtonWrap>
      </div>
      {showListOfEvents(props.events)}
    </div>
  )
}

export default EventsEditList