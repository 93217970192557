/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvents = /* GraphQL */ `
  mutation CreateEvents(
    $input: CreateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    createEvents(input: $input, condition: $condition) {
      id
      startDate
      endDate
      message
      description
      location {
        locationName
        locationLink
      }
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvents = /* GraphQL */ `
  mutation UpdateEvents(
    $input: UpdateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    updateEvents(input: $input, condition: $condition) {
      id
      startDate
      endDate
      message
      description
      location {
        locationName
        locationLink
      }
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvents = /* GraphQL */ `
  mutation DeleteEvents(
    $input: DeleteEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    deleteEvents(input: $input, condition: $condition) {
      id
      startDate
      endDate
      message
      description
      location {
        locationName
        locationLink
      }
      title
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDailyMessage = /* GraphQL */ `
  mutation CreateDailyMessage(
    $input: CreateDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    createDailyMessage(input: $input, condition: $condition) {
      id
      title
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDailyMessage = /* GraphQL */ `
  mutation UpdateDailyMessage(
    $input: UpdateDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    updateDailyMessage(input: $input, condition: $condition) {
      id
      title
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDailyMessage = /* GraphQL */ `
  mutation DeleteDailyMessage(
    $input: DeleteDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    deleteDailyMessage(input: $input, condition: $condition) {
      id
      title
      message
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
