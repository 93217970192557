import React from 'react'
import { useState } from 'react';
import './Event.css'
import { faCalendar, faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalView from '../../modalview/ModalView';

function Event(props) {

    const [modalBody, setModalBody] = useState(null)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const date = new Date(props.information.startDate);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateString = date.toLocaleDateString('es-ES', options);
    const dateString2 = dateString.charAt(0).toUpperCase() + dateString.slice(1);
    const endDate = new Date(props.information.endDate);
    const endDateString = endDate.toLocaleDateString('es-ES', options);
    const endDateString2 = endDateString.charAt(0).toUpperCase() + endDateString.slice(1);
    const dayFormatter = new Intl.DateTimeFormat('es-ES', { day: 'numeric' });
    const day = dayFormatter.format(date)
    const formatter = new Intl.DateTimeFormat('es-ES', { month: 'short' });
    const month = capitalizeFirstLetter(formatter.format(date));
    const year = date.getUTCFullYear();
    const title = props.information.title;
    const message = props.information.message;
    const locationName = props.information.location.locationName;
    const locationLink = props.information.location.locationLink;

    const [showModal, setShowModal] = useState(false)

    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    const getTimeOfDay = (date) => {
        let hour = date.getHours();
        let ampm = hour >= 12 ? 'PM' : 'AM';
        let hours = hour % 12;
        hours = hours ? hours : 12;
        const hourString = pad(hours, 2)
        return hourString + ":" + pad(date.getMinutes(), 2) + " " + ampm;
    }

    const startDateHour = getTimeOfDay(date);

    function showModalView() {
        console.log(date.getDay())
        let dateRangeString;
        if (date.getDay() == endDate.getDay() && date.getMonth() == endDate.getMonth()) {
            if (date.getHours() == endDate.getHours() && date.getMinutes() == endDate.getMinutes()) {
                dateRangeString = dateString2;
            } else {
                dateRangeString = `${dateString}\n${getTimeOfDay(date)} - ${getTimeOfDay(endDate)}`
            }
        } else {
            dateRangeString = `${dateString2} - ${endDateString2}`
        }
        const messageToBeShown = `\n${capitalizeFirstLetter(dateRangeString)}\n${locationName}\n${locationLink}\n\n${message}`
        setModalBody(messageToBeShown)
        setShowModal(true);
    }

    function closeModalView() {
        setShowModal(false);
    }

  return (
    <div className='event-container'>
        <div className='calendar-container' onClick={showModalView}>
            <FontAwesomeIcon className='calendar-icon' icon={faCalendar}/>
            <div className='date-container'>
                <div className='date-text day'><strong>{day}</strong></div>
            </div>
            <div className='month'><strong>{month}</strong></div>
        </div>
        <hr className='vertical'></hr>
        <div className='event-information-container'>
            <h2>{title}</h2>
            <div className='location-container'>
                <FontAwesomeIcon className='location-icon' icon={faClock} />
                <p>{startDateHour == "00:00" ? "Todo el día" : startDateHour}</p>
            </div>
        </div>
        <ModalView show={showModal} onClose={closeModalView} title={title} body={modalBody}/>
    </div>
  )
}

export default Event