import { Auth } from "aws-amplify"
import Cookies from "js-cookie"

const createAuthRepo = (apiClient) => {
  const attemptLogin = async (email, password) => {
    const body = {
      email: {email},
      password: {password}
    }
    const headers = {
      headers: {
        'content-type': 'application/json'
      }
    }
    const response = await apiClient.execute('/getAuth', 'POST', headers, body)
    if (response.authorization) {
      return response.authorization;
    }
    return new Error("efe login");
  }

  const isUserAuthenticated = async () => {
    const value = await Auth.currentAuthenticatedUser();
    return value;
  }

  const sendLoginAttempt = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password);
      return user;
    } catch (error) {
      return error;
    }
  }

  const finishPasswordSetup = async (user, newPassword) => {
    Auth.completeNewPassword(user, newPassword)
    .then(user => {
    })
    .catch(e => {
      console.log(e)
    })
  }

  const forgotPasswordFinish = async (username, code, newPassword) => {
    const result = Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {return data})
      .catch((err) => {return err});
      return result;
  }

  const logout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
  return {attemptLogin, sendLoginAttempt, logout, finishPasswordSetup, forgotPasswordFinish, isUserAuthenticated}
}

export default createAuthRepo;