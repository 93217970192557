import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import ButtonWrap from '../../components/input/button/ButtonWrap'
import TextInput from '../../components/input/textfield/TextInput'
import './Login.css'
import createApiClient from '../../infrastructure/apis/client/ApiClient';
import apiConfig from '../../infrastructure/apis/client/ApiConfig';
import createAuthRepo from '../../infrastructure/repos/AuthRepo';
import { useNavigate } from 'react-router-dom';
import { ReactReduxContext } from 'react-redux';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Login() {
  const { store } = useContext(ReactReduxContext)
  const navigate = useNavigate();

  const apiClient = createApiClient(apiConfig)
  const authRepo = createAuthRepo(apiClient)
  const theme = createTheme({
    palette: {
      primary: {
        main: '#282c34',
      },
      secondary: {
        main: '#edf2ff',
      },
    },
  });

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (e) => {
    setPassword(e.target.value)
  }

  const changeEmail = (e) => {
    setEmail(e.target.value)
  }

  const attemptLogin = () => {
    authRepo.sendLoginAttempt(email, password)
    .then(user => {
      if (!(user instanceof Error)) {
        store.dispatch({
          type: 'auth/addAuth',
          payload: user
        })
        if (user.challengeName == null) {
          navigate("/admin/dashboard");
        } else {
          navigate('/admin/complete-password')
        }
      } else {
        if (user.name == 'PasswordResetRequiredException') {
          navigate("/admin/reset-password")
        }
      }
    })
    .catch(e => {console.log(e)})
  }

  useEffect(() => {
    const result = authRepo.isUserAuthenticated()
    .then(user => {
      navigate("/admin/dashboard");
    })
    .catch(error => {
      console.log(error)
    });
  }, [])
  return (
    <div className='login-container'>
      <div className='login-view'>
        <h1 className='login-title'>Ingreso</h1>
        <ThemeProvider theme={theme}>
          <div className='login-form-container'>
            <TextInput onChange={changeEmail} id="email-input" label="Correo Electrónico" variant="standard" />
            <TextInput 
            onChange={changePassword} 
            className='login-input' 
            id="password-input" 
            label="Contraseña"
            variant="standard" 
            type={showPassword ? 'text' : 'password'}
            InputProps={
              {
                endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }
            }
            />
          </div>
          <ButtonWrap onClick={attemptLogin} variant="contained">Ingresar</ButtonWrap>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default Login