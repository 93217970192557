import React, { useRef, useState } from 'react'
import PhotoFrame from '../photoframe/PhotoFrame'
import "./PhotoGallery.css"
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import PhotoViewer from '../photoviewer/PhotoViewer'


const LEFT = 0;
const RIGHT = 1;
const MIDDLE = 2;

function PhotoGallery(props) {
  const ref = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(LEFT);
  const [displayed, setDisplayed] = useState(false);
  const [imageIndex, setImageIndex] = useState(-1);

  const handleScroll = (event) => {
    if (event != undefined) {
      const scrollMax = event.target.scrollWidth - event.target.offsetWidth;
      const scrollLeft = event.target.scrollLeft;
      const percentage = scrollLeft / scrollMax;
      const threshold = 0.1;
      if (percentage < threshold) {
        setScrollPosition(LEFT)
      } else if (percentage > 1-threshold) {
        setScrollPosition(RIGHT)
      } else {
        setScrollPosition(MIDDLE);
      }
    }
  }

  const handleClick = (event) => {
    const frameClassName = event.target.parentElement.className;
    const frameIndex = frameClassName.split(' ')[1];
    setDisplayed(true);
    setImageIndex(frameIndex);
  }

  const closeViewer = () => {
    const body = document.body;
    body.classList.toggle('noscroll', false);
    setDisplayed(false);
  }

  const nextPicture = () => {
    const index = parseInt(imageIndex)
    if (index >= props.imgs.length - 1) {
      setImageIndex(0);
    } else {
      setImageIndex(index + 1)
    }
  }

  const previousPicture = () => {
    const index = parseInt(imageIndex)
    if (index <= 0) {
      setImageIndex(props.imgs.length -1);
    } else {
      setImageIndex(index - 1)
    }
  }

  useEffect(() => {
    handleScroll();
    const element = ref.current;
    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll)
    };
  },[])

  const renderImage = (img, index) => {
    return (
        <PhotoFrame img={img} onClick={handleClick} key={index} elementIndex={index}/>
    )
  }

  const renderViewer = () => {
    const body = document.body;
    body.classList.toggle('noscroll', true);
    return (<PhotoViewer 
    close={closeViewer}
    img={props.imgs[imageIndex]}
    onNext={nextPicture}
    onPrevious={previousPicture}
    />)
  }

  return (
    <div className='gallery-container'>
      <div className='scroll-indicator-view'>
        <div className='scroll-indicator-background'/>
        <div className='scroll-indicator-icons'>
          <div className={scrollPosition != RIGHT ? 'arrow left hidden' : 'arrow left'}>
            <div className='arrow-background left'/>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className={scrollPosition != LEFT ? 'arrow right hidden' : 'arrow right'}>
            <div className='arrow-background right'/>
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
      <div className='photoGallery' ref={ref}>
          {props.imgs.map((img, index) => renderImage(img, index))}
      </div>
      {displayed ? renderViewer() : null}
    </div>
  )
}

export default PhotoGallery