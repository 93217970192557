import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import './DateInput.css'

function DateInput(props) {

  const sxModifier={
    width: '100%'
  }

  return (
    <div className='date-input'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker sx={sxModifier} {...props} />
      </LocalizationProvider>
    </div>
  )
}

export default DateInput