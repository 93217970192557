export const createNewEvent = (event) => {
  return `mutation editEvent {
    createEvents(input: {description: "${event.description}", endDate: "${event.endDate}", location: {locationLink: "${event.location.locationLink}", locationName: "${event.location.locationName}"}, message: "${event.message}", startDate: "${event.startDate}", title: "${event.title}"}) {
      id
    }
  }`
}

export const deleteEventWithId = (id, version) => {
  return `mutation deleteEvent {
    deleteEvents(input: {id: "${id}", _version: ${version}}) {
      id
      _version
    }
  }`
}

export const editEvent =  `mutation updateEvent($input: UpdateEventsInput!) {
  updateEvents(input: $input) {
    id
    title
    _version
  }
}
  
`