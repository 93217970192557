import axios from 'axios';

const createApiClient = (apiConfig) => {
  const execute = async (path, type, headers, payload) => {

    let config = {};
    config.headers = headers;
    let response;
    switch(type) {
      case 'GET': 
        response = await axios.get(
        apiConfig().getUrl() + path,
        config
      );
      break;
      case 'POST': 
        response = await axios.post(
        apiConfig().getUrl() + path,
        payload,
        config
      );
      break;
      default: 
        throw new Error('Http type error')
    }
    if (!response?.data?.data) {
      if(response?.data?.errors) {
        console.log(response.data.errors);
      }
      throw new Error("error")
    }
    return response.data.data;
  }
  return {
    execute
  }
}

export default createApiClient;