import { Amplify, Storage } from "aws-amplify"
import awsmobile from "../../aws-exports";

const createImagesRepo = () => {
  const listPictures = () => {
    Storage.list('public/')
    .then(({ results }) => console.log(results))
    .catch((err) => console.log(err));
  }
  return  {listPictures}
}

export default createImagesRepo;