import { API, graphqlOperation } from "aws-amplify"
import { getDailyMessage, getPastDailyMessage } from "../graphql/Queries"
import { updateDailyMessage as updateMessageMutation } from "../../graphql/mutations"


const createMessageRepo = () => {
  const fetchMessage = async () => {
    return await API.graphql(graphqlOperation(getDailyMessage))
  }

  const fetchPastMessage = async () => {
    return await API.graphql(graphqlOperation(getPastDailyMessage))
  }

  const updateDailyMessage = async (dailyMessage) => {
    return API.graphql({
      query: updateMessageMutation,
      variables: {input: dailyMessage}
    })
  }
  return {
    fetchMessage,
    fetchPastMessage,
    updateDailyMessage
  }
}

export default createMessageRepo;