import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './ActivityIcon.css'

function ActivityIcon(props) {
  return (
    <div className='activityIcon'>
        <div className='image'>
            <FontAwesomeIcon icon={props.icon}/>
        </div>
        <h3>{props.children}</h3>
    </div>
  )
}

export default ActivityIcon