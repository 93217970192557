import './EventsEditMain.css'
import React, { useEffect, useState } from 'react'
import createEventsRepo from '../../../../infrastructure/repos/EventsRepo';
import apiConfig from '../../../../infrastructure/apis/client/ApiConfig';
import createApiClient from '../../../../infrastructure/apis/client/ApiClient';
import EventsEditList from './eventseditlist/EventsEditList';
import EventsEdit from './eventsedit/EventsEdit';

function EventsEditMain() {
  const apiClient = createApiClient(apiConfig);
  const eventsRepo = createEventsRepo(apiClient);

  const [events, setEvents] = useState(null)
  const [editing, setEditing] = useState(null);

  useEffect(() => {
    eventsRepo.fetchAllEvents().then(data => {
      setEvents(data.data.listEvents.items)
    }).catch(error => {console.log(error)})
  }, [editing])

  const reloadEvents = () => {
    eventsRepo.fetchAllEvents().then(data => {
      setEvents(data.data.listEvents.items)
    }).catch(error => {console.log(error)})
  }

  const handleEditMode = (event) => {
    setEditing(event);
  }

  if (editing == null) {
    return (
      <div>
        <EventsEditList reload={reloadEvents} events={events} edit={handleEditMode}/>
      </div>
    )
  } else {
    return (
      <div>
        <EventsEdit event={editing} edit={handleEditMode} />
      </div>
    )
  }
}

export default EventsEditMain