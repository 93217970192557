import './Home.css';
import Navbar from './../../components/navigationBar/Navbar';
import { createRef, useEffect, useState } from 'react'
import { faPeopleGroup, faBookBible, faChurch, faPlaceOfWorship, faPersonHiking, faFish, faCalendarDays, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import DailyMessage from './../../components/dailymessage/DailyMessage';
import Events from './../../components/events/Events';
import InformationSection from './../../components/informationsection/InformationSection';
import ActivityIcon from './../../components/activityicon/ActivityIcon'
import img1 from './../../resources/images/img1.jpg'
import img2 from './../../resources/images/img2.jpg'
import img3 from './../../resources/images/img3.jpg'
import img4 from './../../resources/images/img4.jpg'
import img5 from './../../resources/images/img5.jpg'
import img6 from './../../resources/images/img6.jpg'
import img7 from './../../resources/images/img7.jpg'
import img8 from './../../resources/images/img8.jpg'
import img9 from './../../resources/images/img9.jpg'
import img10 from './../../resources/images/img10.jpg'
import img11 from './../../resources/images/img11.jpg'
import PhotoGallery from './../../components/photogallery/gallery/PhotoGallery';
import createEventsRepo from '../../infrastructure/repos/EventsRepo';
import apiConfig from '../../infrastructure/apis/client/ApiConfig';
import createApiClient from '../../infrastructure/apis/client/ApiClient';
import createMessageRepo from '../../infrastructure/repos/MessageRepo';
import createImagesRepo from '../../infrastructure/repos/ImagesRepo';


function Home() {
  const apiClient = createApiClient(apiConfig)
  const eventsRepo = createEventsRepo(apiClient)
  const imagesRepo = createImagesRepo();
  const messageRepo = createMessageRepo();
  const topRef = createRef();
  const aboutRef = createRef();
  const activitiesRef = createRef();
  const galleryRef = createRef();
  const contactRef = createRef();

  const emptyDailyMessage = {title: "", message: ""};
  const [events, setEvents] = useState([]);
  const [dailyMessage, setDailyMessage] = useState(emptyDailyMessage)
  const [pastMessage, setPastMessage] = useState(emptyDailyMessage)
  const [isPresent, setIsPresent] = useState(true)

  const toggleTodayMessage = () => {
    setIsPresent(!isPresent);
  }

  useEffect(() => {
    messageRepo.fetchMessage()
    .then(data => {
      setDailyMessage(data.data.getDailyMessage)
    })
    .catch(error => {
      console.log(error)
      setDailyMessage(emptyDailyMessage)
    })
    messageRepo.fetchPastMessage()
    .then(data => {
      setPastMessage(data.data.getDailyMessage)
    })
    .catch(error => {
      console.log(error)
      setPastMessage(emptyDailyMessage)
    })
    eventsRepo.fetchFutureEvents().then(data => {
      setEvents(data.data.listEvents.items)
    })
    .catch(error => {
      console.log(error)
    })
    imagesRepo.listPictures();
  }, [])

  return (
    <>
      <head>
      <script src="https://kit.fontawesome.com/10039bc265.js" crossOrigin="anonymous"></script>
      <script src="https://unpkg.com/primereact/core/core.min.js"></script>
      <script src="https://unpkg.com/primereact/image/image.min.js"></script>
    </head>
      <div className='mainContainer'>
        <div className='navigation-bar'>
          <Navbar 
          topRef={topRef}
          aboutRef={aboutRef}
          activitiesRef={activitiesRef}
          galleryRef={galleryRef}
          contactRef={contactRef}
          />
        </div>
        <div className="startingPage" ref={topRef}>
          <DailyMessage 
          title={(isPresent) ? dailyMessage.title : pastMessage.title} 
          message={(isPresent) ? dailyMessage.message : pastMessage.message} toggleMessage={toggleTodayMessage} isPresent={isPresent} />
          <Events events={events} />
        </div>
        <div className="informationSection" ref={aboutRef}>
          <InformationSection>
            <h1>ACERCA DEL MOVIMIENTO</h1>
            <hr></hr>
            <p>
            Somos una Asociación católica de oración de intercesión, integrada por fieles laicos de la Arquidiócesis de Guatemala. Pertenecemos a la Comisión Arquidiócesana de laicos (CAMOVAL).
            </p>
            <h2>
            OBJETIVO ESPECÍFICO
            </h2>
            <p>
            Nuestro objetivo es colocarnos en la brecha (en medio de) entre Dios y la humanidad; para que los hombres y las mujeres acepten el proyecto de Dios para su vida. Los proyectos de Dios son mejores que los nuestros. Su plan es que todos los hombres se salven y lleguen al conocimiento de la verdad (1 Tm 2,4).
            </p>
          </InformationSection>
        </div>
        <div className="activitiesSection" ref={activitiesRef}>
          <InformationSection>
            <h1>Actividades</h1>
            <hr></hr>
            <div className='iconsGrid'>
              <ActivityIcon icon={faUsers}>Reuniones de<br/>intercesión</ActivityIcon>
              <ActivityIcon icon={faBookBible}>Retiros</ActivityIcon>
              <ActivityIcon icon={faPlaceOfWorship}>Misas especiales</ActivityIcon>
              <ActivityIcon icon={faPeopleGroup}>Vigilias</ActivityIcon>
              <ActivityIcon icon={faChurch}>Horas Santas</ActivityIcon>
              <ActivityIcon icon={faPersonHiking}>Misiones de<br/>Intercesión</ActivityIcon>
              <ActivityIcon icon={faFish}>Ayunos</ActivityIcon>
              <ActivityIcon icon={faCalendarDays}>Novenas</ActivityIcon>
            </div>
          </InformationSection>
        </div>
        <div className='galery-section' ref={galleryRef}>
          <div className='images-grid'>
            <PhotoGallery imgs={[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]}/>
          </div>
        </div>
        <div className='contact-section' ref={contactRef}>
          <InformationSection>
            <h1>Contactanos!</h1>
            <hr></hr>
            <div className='contact-icons'>
              <a href='https://www.facebook.com/mcibguatemala'>
                <ActivityIcon icon={faFacebookF}>@mcibguatemala</ActivityIcon>
              </a>
              <a href='mailto:ministeriobetel.mcib@gmail.com'>
                <ActivityIcon icon={faEnvelope}>ministeriobetel.mcib@gmail.com</ActivityIcon>
              </a>
            </div>
          </InformationSection>
        </div>
      </div>
    </>
  );
}

export default Home;
