import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useEffect, useState} from 'react'
import React from 'react'
import './PhotoViewer.css'
import { faAngleLeft, faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons'

function PhotoViewer(props) {
  const [isMobile, setMobile] = useState(window.innerWidth <= 960)
  const [touchPosition, setTouchPosition] = useState(null);

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  }

  const handleTouchMove = (e) => {
    if (!isMobile) {
      return;
    }
    const touchDown = touchPosition;

    if (touchDown == null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      props.onNext();
    }

    if (diff < -5) {
      props.onPrevious();
    }

    setTouchPosition(null);
  }

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 960)
    }
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderLeftArrow = () => {
    const leftArrowElement = (
      <div className='photoNavigation left' onClick={props.onPrevious}>
        <FontAwesomeIcon icon={faAngleLeft}/>
      </div>
    );
    return isMobile ? null : leftArrowElement
  }

  const renderRightArrow = () => {
    const rightArrowElement = (
      <div className='photoNavigation right' onClick={props.onNext}>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    );
    return isMobile ? null : rightArrowElement;
  }

  return (
    <div className='photoViewer'>
      <div className='photoController'>
        <div className='manageControls upper'>
          <div className='photoNavigation close' onClick={props.close}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        <div className='middlePartControls'>
          {renderLeftArrow()}
          <div className='picture' onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}>
            <img src={props.img} style={{maxWidth:"80vw", objectFit:"contain"}}></img>
          </div>
          {renderRightArrow()}
        </div>
      </div>
    </div>
  )
}

export default PhotoViewer